import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/modules/index';
import adminService from '@/services/admin-service';
import { User } from '../store/models/ProfileModel';
import { AuthService } from '@/services/auth-service';
import { Component } from 'vue-property-decorator';
import { NoAccessTypes } from '@/ConstValues';
import { component } from 'vue/types/umd';

Component.registerHooks(['beforeRouteUpdate']);
Vue.use(VueRouter);

const ifNotAuthenticated = async (to: any, from: any, next: any) => {
  if (store.getters['profile/isAuthenticated']) {
    const { data } = await AuthService.isConfirmed();
    if (!data) {
      adminService.addActivityStream(
        `User navigated to ${to.name}, but redirected to no access`
      );
      next({
        name: 'NoAccess',
        params: {
          type: NoAccessTypes.NotConfirmed
        }
      });
    } else {
      next();
    }
  } else {
    const redirectToLogin = () => {
      if (from.fullPath !== '/') {
        return next({
          name: 'login',
          params: {
            nextUrl: to.fullPath
          }
        });
      } else {
        return next({
          name: 'Home'
        });
      }
    };
    const c = localStorage.getItem('user')!;
    if (!c) return redirectToLogin();
    const user = JSON.parse(c) as User;
    if (!user || !user.isAuthenticated) return redirectToLogin();
    const { data } = await AuthService.isConfirmed();
    if (!data) {
      return next({
        name: 'NoAccess',
        params: {
          type: NoAccessTypes.NotConfirmed
        }
      });
    } else {
      adminService.addActivityStream(`User navigated to ${to.name}`);
      next();
    }
  }
};
const ifNotAdmin = async (to: any, from: any, next: any) => {
  const c = localStorage.getItem('user')!;
  const user = JSON.parse(c) as User;
  let isAuthenticated = false;
  if (user && user.isAuthenticated) {
    try {
      const isUserAdmin = await AuthService.isAdmin();
      if (isUserAdmin.data) {
        isAuthenticated = true;
        next();
      }
    } catch (err) {
      console.log(err);
    }
  }
  if (!isAuthenticated) {
    return next({
      name: 'login',
      params: {
        nextUrl: to.fullPath
      }
    });
  }
};

const routes = [
  {
    path: '*',
    redirect: '/notfound'
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/public/index.vue')
  },
  {
    path: '/homepage',
    redirect: '/'
  },
  {
    path: '/redirectFromTransparency',
    name: 'redirectFromTransparency',
    component: () =>
      import(/* webpackChunkName: "redirectFromTransparency" */ '../views/Home.vue')
  },
  {
    path: '/accountsettings',
    name: 'Account Settings',
    component: () =>
      import(/* webpackChunkName: "AccountSettings" */ '../views/AccountSettings.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/activitylog',
    name: 'Activity Log',
    component: () =>
      import(/* webpackChunkName: "ActivityLog" */ '../views/Admin/ActivityLog.vue'),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/addressbook',
    name: 'addressbook',
    component: () =>
      import(/* webpackChunkName: "AddressBook" */ '../views/AddressBook.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/admindashboard',
    name: 'AdminDashboard',
    component: () =>
      import(
        /* webpackChunkName: "AdminDashboard" */ '../views/Admin/AdminDashboard.vue'
      ),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/approveItem/:id/:shouldClose?',
    name: 'ItemApproval',
    props: true,
    component: () =>
      import(/* webpackChunkName: "ItemApproval" */ '../views/ItemApproval.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/barcodePlacement/:itemId',
    name: 'BarcodePlacement',
    props: true,
    component: () =>
      import(/* webpackChunkName: "BarcodePlacement" */ '../views/BarcodePlacement.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  // {
  //   path: '/changePassword',
  //   name: 'changePassword',
  //   component: () =>
  //     import(/*webpackChunkName: "changePassword"*/ '../views/ChangePassword.vue')
  // },
  {
    path: '/companyprofile',
    name: 'companyprofile',
    component: () =>
      import(/* webpackChunkName: "CompanyProfile" */ '../views/CompanyProfile.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/custom-labels',
    name: 'CustomLabels',
    component: () =>
      import(/* webpackChunkName: "CustomLabels" */ '../views/public/CustomLabels.vue')
  },
  {
    path: '/custom-labels/health-wellness-labels',
    name: 'HealthWellnessLabels',
    component: () =>
      import(
        /* webpackChunkName: "HealthWellnessLabels" */ '../views/public/HealthWellnessLabels.vue'
      )
  },
  {
    path: '/custom-labels/food-labels',
    name: 'FoodLabels',
    component: () =>
      import(/* webpackChunkName: "FoodLabels" */ '../views/public/FoodLabels.vue')
  },
  {
    path: '/custom-labels/cannabis-labels',
    name: 'CannabisLabels',
    component: () =>
      import(
        /* webpackChunkName: "CannabisLabels" */ '../views/public/CannabisLabels.vue'
      )
  },
  // {
  //   path: '/custom-labels/custom-clothing-labels',
  //   name: 'CustomClothingLabels',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CustomClothingLabels" */ '../views/public/CustomClothingLabels.vue'
  //     )
  // },
  {
    path: '/custom-labels/discount-labels',
    name: 'DiscountLabels',
    component: () =>
      import(
        /* webpackChunkName: "DiscountLabels" */ '../views/public/DiscountLabels.vue'
      )
  },
  {
    path: '/custom-labels/skin-beauty-labels',
    name: 'SkinAndBeautyLabels',
    component: () =>
      import(
        /* webpackChunkName: "SkinAndBeautyLabels" */ '../views/public/SkinAndBeautyLabels.vue'
      )
  },
  {
    path: '/custom-labels/bath-body-products',
    name: 'BathBodyProducts',
    component: () =>
      import(
        /* webpackChunkName: "BathBodyProducts" */ '../views/public/BathBodyProducts.vue'
      )
  },
  {
    path: '/custom-labels/beverage-and-drink-labels',
    name: 'BeverageAndDrinkLabels',
    component: () =>
      import(
        /* webpackChunkName: "BeverageAndDrinkLabels" */ '../views/public/BeverageAndDrinkLabels.vue'
      )
  },
  {
    path: '/custom-labels/pet-product-labels',
    name: 'PetProductLabels',
    component: () =>
      import(
        /* webpackChunkName: "PetProductLabels" */ '../views/public/PetProductLabels.vue'
      )
  },
  {
    path: '/templates',
    name: 'DieTemplates',
    component: () =>
      import(/* webpackChunkName: "DiesTemplates" */ '../views/DiesTemplates.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/editcustomer/:CustomerId',
    name: 'EditCustomer',
    component: () =>
      import(/* webpackChunkName: "EditCustomer" */ '../views/Admin/EditCustomer.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/invoicemanagement',
    name: 'invoicemanagement',
    component: () =>
      import(
        /* webpackChunkName: "InvoiceManagement" */ '../views/InvoiceManagement.vue'
      ),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/items/info/:id?',
    name: 'ItemInformation',
    component: () =>
      import(
        /* webpackChunkName: "ItemInformation" */ '../views/items/ItemInformation.vue'
      ),
    props: true,
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/items/addedittransparency/:id',
    name: 'AddEditTransparencyItem',
    component: () =>
      import(
        /* webpackChunkName: "AddEditTransparencyItem" */ '../views/items/AddEditTransparencyItem.vue'
      ),
    props: true,
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/Login/:confirmed?',
    name: 'login',
    alias: '/account/login',
    props: true,
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/LoginError',
    name: 'loginerror',
    component: () =>
      import(/* webpackChunkName: "LoginError" */ '../views/LoginError.vue')
  },
  {
    path: '/managecustomer',
    name: 'ManageCustomer',
    component: () =>
      import(
        /* webpackChunkName: "ManageCustomer" */ '../views/Admin/ManageCustomer.vue'
      ),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/crm',
    name: 'Crm',
    component: () => import(/* webpackChunkName: "Crm" */ '../views/Admin/Crm.vue'),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/managecache',
    name: 'ManageCache',
    component: () =>
      import(/* webpackChunkName: "ManageCache" */ '../views/Admin/ManageCache.vue'),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/items',
    name: 'ManageItems',
    component: () =>
      import(/* webpackChunkName: "ManageItems" */ '../views/items/ManageItems.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/manageusers',
    name: 'ManageUsers',
    component: () =>
      import(/* webpackChunkName: "ManageUsers" */ '../views/Admin/ManageUsers.vue'),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/noaccess',
    name: 'NoAccess',
    component: () => import(/* webpackChunkName: "NoAccess" */ '../views/NoAccess.vue')
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/NotFound.vue')
  },
  {
    path: '/OrderByFile',
    name: 'OrderByFile',
    component: () =>
      import(/* webpackChunkName: "OrderByFile" */ '../views/Admin/OrderByFile.vue'),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/orders/orderconfirmation/:po',
    name: 'orderConfirmation',
    component: () =>
      import(
        /* webpackChunkName: "orderConfirmation" */ '../views/orders/OrderConfirmation.vue'
      ),
    props: true,
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/orders/orderdetails/:po',
    name: 'OrderDetails',
    component: () =>
      import(/* webpackChunkName: "OrderDetails" */ '../views/orders/OrderDetails.vue'),
    props: true,
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/Orders/:hasPlacedOrder?',
    name: 'Orders',
    props: true,
    component: () =>
      import(/* webpackChunkName: "Orders" */ '../views/orders/Orders.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/orders/order/:po?/:orderlineId?/:isReordering?/:isCartOrder?',
    name: 'OrderView',
    props: true,
    component: () =>
      import(/* webpackChunkName: "OrderView" */ '../views/orders/OrderView.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/passwordreset/:code?/:vcode?',
    name: 'PasswordReset',
    props: true,
    component: () =>
      import(/* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue')
  },
  {
    path: '/pickupPackage/:id',
    name: 'PickupPackage',
    props: true,
    component: () =>
      import(/* webpackChunkName: "PickupPackage" */ '../views/PickupPackage.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  {
    path: '/Quoter',
    name: 'Quoter',
    component: () => import(/* webpackChunkName: "Quoter" */ '../views/Quoter.vue'),
    beforeEnter: ifNotAuthenticated as any
  },
  {
    path: '/TransparencyQuoter',
    name: 'TranQuoter',
    component: () =>
      import(/* webpackChunkName: "TranQuoter" */ '../views/TransparencyQuoter.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
  },
  {
    path: '/AddPayment',
    name: 'AddPayment',
    component: () =>
      import(/* webpackChunkName: "AddPayment" */ '../views/StripePaymentWindow.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Test" */ '../views/Test.vue'),
    props: true,
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/Admin/CodeManager',
    name: 'CodeManager',
    component: () =>
      import(
        /* wepackChucnkName: "CodeManager"*/ '../views/Admin/TransparencyCodeManager.vue'
      ),
    beforeEnter: ifNotAdmin as any
  },
  {
    path: '/transparency',
    name: 'Transparency',
    component: () =>
      import(/* webpackChunkName: "Transparency" */ '../views/Transparency.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
